.main {
    height: 100vh;
    background-color: #6F7E84;
}

#backPara {
    top: 25px;
    cursor: pointer;
    height: 50px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
}

#ClosePara {
    top: 25px;
    right: 45px;
    cursor: pointer;
}

.closeImgLink {
    width: 65px;
}

.copyLink {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.LinkHead {
    /*width: 100vw;*/
    /*height: 100px;*/
    /*background-color: white;*/
    position: relative;
    margin-top: -30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LinkHeadTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.sampleBtn {
    width: 260px;
    border-radius: 26px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.backIconTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
}

.labeltxt {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.Footer {
    /*background-color: black;*/
    display: flex;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
}

/*.FooterBtn {*/
/*    background-color: black;*/
/*    height: 5vh;*/
/*    width: 180px;*/
/*    border-radius: 26px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    display: flex;*/
/*    position: absolute;*/
/*    color: orange;*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 18px;*/
/*    z-index: 5;*/
/*}*/

.footerLink {
    height: 15vh;
    width: 90vw;
    /*background-color: red;*/
    display: flex;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    margin-right: 3%;
    /*padding-right: 40px;*/
}

.genarateBtn {
    background-color: black;
    height: 45px;
    width: 180px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: orange;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    z-index: 5;
}

.FooterLinkBtn {
    background-color: black;
    height: 45px;
    width: 160px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    color: #FF9600;
    font-size: 16px;
    font-weight: bold;
}

.setWidthLink {
    width: 70%;
}

.inputsLink {
    border-radius: 20px;
    width: 65%;
    height: 35px;
}

.inputs1Link {
    border-radius: 20px;
    /*width: 85%;*/
    height: 35px;
}


@media screen and (max-width: 470px) {
    .Footer {
        justify-content: center;
        padding-right: 0;
    }
}

@media screen and (max-width: 1000px) {
    .inputs {
        width: 50%;
    }

    .inputs1 {
        width: 80%;
    }

    .inputs1Link {
        border-radius: 20px;
        width: 90%;
        height: 35px;
    }

    .copyLink {
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin-left: 50px;
    }
}

@media screen and (max-width: 470px) {
    .inputs {
        width: 90%;
    }

    .inputs1 {
        width: 90%;
    }
}

@media screen and (max-width: 390px) {
    .inputs {
        width: 87%;
    }

    .inputs1 {
        width: 87%;
    }
}

@media screen and (max-width: 1000px) {
    #sbtn {
        margin-top: 20px;
    }

    #sbtn1 {
        margin-top: 20px;
    }

    #sbtn2 {
        margin-top: 20px;
    }
}
/*@media screen and (max-width: 1100px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        background-color: green;*/
/*        position: absolute;*/
/*        margin-top: -50%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1300px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        background-color: green;*/
/*        position: absolute;*/
/*        margin-top: -40%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1400px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: red;*!*/
/*        position: absolute;*/
/*        margin-top: -20%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1200px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        background-color: blue;*/
/*        position: absolute;*/
/*        margin-top: -45%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/

@media screen and (max-width: 1500px) {
    .LinkHead {
        width: 100vw;
        height: 80px;
        /*background-color: purple;*/
        position: absolute;
        margin-top: -45%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/*@media screen and (min-width: 1600px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -30%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 1700px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -28%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 1800px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -28%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 1900px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -25%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (max-width: 2000px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -40%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 2100px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -23%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 2200px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -23%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 2300px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -23%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 2400px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -28%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 2500px) {*/
/*    .LinkHead {*/
/*        width: 100vw;*/
/*        height: 80px;*/
/*        !*background-color: purple;*!*/
/*        position: absolute;*/
/*        margin-top: -28%;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/
/*}*/

@media screen and (max-width: 991px) {
    .LinkHead {
        width: 100vw;
        height: 80px;
        /*background-color: red;*/
        position: absolute;
        margin-top: -50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LinkHeadTxt {
        font-family: 'Rubik', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }
}

@media screen and (max-width: 992px) {
    .LinkHead {
        width: 100vw;
        height: 80px;
        /*background-color: red;*/
        position: absolute;
        margin-top: -50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LinkHeadTxt {
        font-family: 'Rubik', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }
}

@media screen and (max-width: 1000px) {
    .LinkHead {
        width: 100vw;
        height: 80px;
        /*background-color: red;*/
        position: absolute;
        margin-top: -50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LinkHeadTxt {
        font-family: 'Rubik', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }
}

@media screen and (max-width: 990px) {
    .LinkHead {
        width: 100vw;
        height: 80px;
        /*background-color: green;*/
        position: absolute;
        margin-top: -50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LinkHeadTxt {
        font-family: 'Rubik', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }
}


.mainCop{
    text-align: center;
    margin: 25px;
    font-weight: bold;
    font-size: 25px;
}

.mainGenerator{
    position: relative;
 }
