.mainDocument {
    height: complex;
    background-color: gray;
}

#backPara {
    top: 25px;
    left: 45px;
    cursor: pointer;
}

#ClosePara {
    top: 25px;
    right: 45px;
    cursor: pointer;
}

.closeImgD {
    width: 65px;
}

.subContainer{
    height: 100vmin;
    width: 700px;
    /*background-color: #1c7430;*/
}

.inputs {
    border-radius: 20px;
    width: 90%;
    height: 28px;
    font-size: 13px;
}

.inputs1 {
    border-radius: 20px;
    height: 28px;
    font-size: 12px;
}

.inputs2 {
    border-radius: 20px;
    width: 90%;
    height: 28px;
}

.FooterView {
    height: 50px;
    /*background-color: red;*/
    display: flex;
    right: 0;
    bottom: 0;
    /*width: 200px;*/
    /*align-items: center;*/
    /*margin-top: 400px;*/
    justify-content: flex-end;
    position: fixed;
    margin-bottom: 20px;
    padding-right: 60px;
}

.FooterBtnDocument {
    background-color: black;
    height: 45px;
    width: 160px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    /*position: absolute;*/
    color: orange;
    font-size: 15px;
    font-weight: bold;
}

.form1{
    height: fit-content;
}

.setWidth0 {
    width: 75%;
}

.setWidth1 {
    width: 70%;
}

.setWidth2 {
    width: 80%;
}

.setWidth3 {
    width: 70%;
}

.setWidth5 {
    width: 20%;
    /*background-color: #61dafb;*/
    /*margin-left: 5%;*/
}
.footerView {
    width: 90%;
    /*background-color: #FF9600;*/
    margin-left: 2%;
}
.labelTxtN{
    margin-left: 3%;
    font-size: 13px;
}

.companyLogo{
    width: 150px;
    height: 150px;
    border-radius: 20px;
    border: 1px solid lightgray;
}

.Signature{
    width: 150px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.companySeal{
    width: 250px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.companyLogo2{
    width: 150px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.companyLogoTxt{
    font-size: 24px;
    text-align: center;
    color: lightgray;
}

.companyLogo2Txt{
    font-size: 20px;
    text-align: center;
    color: lightgray;
}

.btn1D{
    background-color: lightgray;
    border-radius: 26px;
    /*height: 20px;*/
    color: white;
    font-size: 12px;
}

.txtArea{
    border-radius: 15px;
    max-height: 130px;
    min-height: 130px;
    border-color: lightgray;
    font-size: 13px;
}

.txtArea::placeholder{
    padding-left: 12px;
}

.txtArea1{
    border-radius: 15px;
    height: 80px;
    width: 450px;
    border-color: lightgray;
    z-index: 10;
    font-size: 12px;
}

.txtArea1::placeholder{
    padding-left: 12px;
    max-height: 30px;
    min-height: 30px;
}

.formDiv{
    height: 120vmin;
    /*overflow-y: scroll;*/
    /*margin-top: 20px;*/
    /*max-width: 3500px;*/
    /*min-width: 700px;*/
}
.subDivNew{
    /*max-width: 700px;*/
}

.subDivNew1{
    /*max-width: 500px;*/
    /*padding-bottom: 100px;*/
}
.footerImg{
    height: 15px;
    width: 630px;
    /*margin-left: 20px;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}


.file-input1{
    display: none;
}

.file-input2{
    display: none;
}

.file-input3{
    display: none;
}
