/*.mainHome {*/
/*    height: 100vh;*/
/*    background-color: #6F7E84;*/
/*}*/

/*.logoutBtn {*/
/*    flex-direction: row;*/
/*    !*background-color: red;*!*/
/*    width: 230px;*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    align-items: center;*/
/*}*/

/*.logoutBtnView {*/
/*    border-radius: 20px;*/
/*    width: 120px;*/
/*    height: 40px;*/
/*    border-color: transparent;*/
/*    background-color: #232323;*/
/*    color: #FF9600;*/
/*}*/

/*.header-Container {*/
/*    flex-direction: row;*/
/*    !*background-color: #1c7430;*!*/
/*    !*background-color: orange;*!*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*}*/

/*.homeLogo {*/
/*    padding-right: 10vh;*/
/*}*/

/*.userProfileLogo {*/
/*    height: 50px;*/
/*    width: 50px;*/
/*    cursor: pointer;*/
/*}*/

/*.FooterHome {*/
/*    right: 0;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    margin-bottom: 45px;*/
/*}*/

/*.FooterBtnHome {*/
/*    background-color: #232323;*/
/*    width: 130px;*/
/*    border-radius: 26px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    display: flex;*/
/*    color: #FFA300;*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 15px;*/
/*    border-color: transparent;*/
/*    margin-left: 1vw;*/
/*}*/

/*.FooterBtnHome1 {*/
/*    background-color: #232323;*/
/*    width: 150px;*/
/*    border-radius: 26px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    display: flex;*/
/*    color: #FFA300;*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 15px;*/
/*    margin-left: 1vw;*/
/*}*/

/*.LogoView {*/
/*    width: 12vw;*/
/*}*/

/*.HeaderlogoHome {*/
/*    width: 180px;*/
/*    height: 140px;*/
/*}*/

/*.headerTextHome {*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 35px;*/
/*    text-align: left;*/
/*    justify-content: center;*/

/*}*/

/*.tblHeadTxt {*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 16px;*/
/*}*/

/*.tblValTxt {*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 14px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    vertical-align: middle !important;*/
/*    min-width: 100px;*/
/*}*/

/*.tblTitle {*/
/*    font-size: 15px;*/
/*    font-weight: bold;*/
/*}*/

/*.tblTitle1 {*/
/*    font-size: 15px;*/
/*    font-weight: bold;*/
/*    padding-left: 5vw;*/
/*}*/

/*.tblTitle2 {*/
/*    font-size: 15px;*/
/*    font-weight: bold;*/
/*    padding-right: 14vw;*/
/*}*/

/*.rowTitle {*/
/*    font-size: 15px;*/
/*    font-weight: bold;*/
/*    padding-left: 2vw;*/
/*}*/

/*.rowInvoiceTitle {*/
/*    font-size: 15px;*/
/*}*/

/*.subView {*/
/*    color: black;*/
/*    display: flex;*/
/*    position: relative;*/
/*    flex-direction: row;*/
/*}*/

/*.subView1 {*/
/*    height: 8vh;*/
/*    width: 72.5vw;*/
/*    color: black;*/
/*    display: flex;*/
/*    position: relative;*/
/*    flex-direction: row;*/
/*    justify-content: flex-end;*/
/*    align-items: center;*/
/*}*/

/*#searchTxtInput {*/
/*    color: black;*/
/*    height: 35px;*/
/*    border-radius: 20px;*/
/*    outline: none;*/
/*    margin-right: 10px;*/
/*    text-align: left;*/
/*}*/

/*#searchTxtInput::placeholder {*/
/*    color: lightgray;*/
/*}*/

/*.subDiv {*/
/*    overflow-y: auto;*/
/*    white-space: nowrap;*/
/*    height: 500px;*/
/*}*/

/*.searchBtnHome {*/
/*    background-color: #FCCF7F;*/
/*    height: 35px;*/
/*    width: 90px;*/
/*    border-radius: 22px;*/
/*    color: #fff;*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 16px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.searchTxt {*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 14px;*/
/*}*/

/*.copy {*/
/*    width: 30px;*/
/*    height: 30px;*/
/*    cursor: pointer;*/
/*}*/

/*.subView2 {*/
/*    height: 6vh;*/
/*    width: 60vw;*/
/*    color: black;*/
/*    display: flex;*/
/*    position: relative;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*}*/

/*.tblRowView {*/
/*    height: 6vh;*/
/*    width: 1460px;*/
/*    color: black;*/
/*    display: flex;*/
/*    position: relative;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    padding-left: 4vw;*/
/*    margin-bottom: 1vh;*/
/*}*/

/*.tblRowSubView {*/
/*    height: 6vh;*/
/*    width: 1300px;*/
/*    color: black;*/
/*    background-color: #FFA300;*/
/*    display: flex;*/
/*    position: relative;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    border-radius: 28px;*/
/*    padding-left: 5px;*/
/*}*/

/*.tblRowSubView1 {*/
/*    height: 5.3vh;*/
/*    width: 500px;*/
/*    color: black;*/
/*    background-color: #FFA300;*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    border-radius: 28px;*/
/*}*/

/*.tblRowInvoiceView {*/
/*    height: 6vh;*/
/*    width: 300px;*/
/*    color: black;*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    margin-left: 2vw;*/
/*}*/

/*.tblRowLinkView {*/
/*    height: 6vh;*/
/*    width: 300px;*/
/*    color: black;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-left: 1vw;*/
/*}*/

/*.tblRowbtnView {*/
/*    flex-direction: row;*/
/*    width: 200px;*/
/*    color: black;*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    align-items: center;*/
/*    text-align: center;*/
/*    margin-top: 5px;*/
/*    vertical-align: middle !important;*/
/*}*/

/*.tblRowbtnView1 {*/
/*    height: 6vh;*/
/*    width: 100px;*/
/*    color: black;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-left: 1vw;*/
/*}*/

/*.rowBtnHome {*/
/*    background-color: #232323;*/
/*    height: 25px;*/
/*    width: 80px;*/
/*    border-radius: 22px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-width: 2px;*/
/*    border-color: white;*/
/*    color: white;*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 9px;*/
/*}*/

/*.rowBtnHome1 {*/
/*    height: 25px;*/
/*    width: 100px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    color: white;*/
/*    font-size: 10px;*/
/*    font-weight: bold;*/
/*    vertical-align: middle !important;*/
/*}*/

/*.tdStyle {*/
/*    background-color: #FCCF7F;*/
/*    width: 350px;*/
/*    min-width: 200px;*/
/*}*/


/*#rowStyle {*/
/*    background-color: #FCCF7F;*/
/*    height: 50px;*/
/*}*/

/*.tablefirsttab {*/
/*    background: #FFA300;*/
/*    border-radius: 26px;*/
/*    height: 50px;*/
/*    padding-left: 10px;*/
/*    align-items: center;*/
/*    display: flex;*/
/*    font-family: 'Rubik', sans-serif;*/
/*    font-size: 14px;*/
/*    text-align: left;*/
/*    left: 10px !important;*/
/*}*/

/*table {*/
/*    border-collapse: separate;*/
/*    border-spacing: 0 10px;*/
/*}*/

/*td:first-child {*/
/*    border-left-style: solid;*/
/*    border-top-left-radius: 28px !important;*/
/*    border-bottom-left-radius: 28px !important;*/
/*    padding-top: 3px;*/
/*    padding-bottom: 3px;*/
/*    padding-left: 3px;*/
/*}*/

/*td:last-child {*/
/*    border-right-style: solid;*/
/*    border-bottom-right-radius: 28px;*/
/*    border-top-right-radius: 28px;*/
/*}*/

/*.tableStyle {*/
/*    overflow-y: auto;*/
/*}*/

/*.tableView {*/
/*    height: 500px;*/
/*    z-index: 10;*/
/*}*/

/*.okBtn {*/
/*    width: 25px;*/
/*    height: 25px;*/
/*}*/

/*.okBtnDownlode {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    right: 0;*/
/*    display: flex;*/
/*    margin-top: 5px;*/
/*    cursor: pointer;*/
/*}*/

/*@media screen and (max-width: 490px) {*/
/*    .subView1 {*/
/*        justify-content: center;*/
/*    }*/

/*    .headerTextHome {*/
/*        font-size: 30px;*/
/*        padding-left: 0;*/
/*        text-align: center;*/
/*    }*/
/*}*/

/*.btn:hover {*/
/*    color: #ffffff !important;*/
/*    text-decoration: none;*/
/*}*/


/*@media (min-width: 0px) and (max-width: 1000px) {*/
/*    .mainHome {*/
/*        height: 100vh;*/
/*        padding-bottom: 20px;*/
/*    }*/
/*}*/

/*@media (max-width: 700px) {*/
/*    .subView {*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*        text-align: center;*/
/*    }*/

/*    #searchTxtInput {*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*.search_div {*/
/*    width: -webkit-fill-available;*/
/*    gap: 6px;*/
/*}*/

/*th {*/
/*    background: #6F7E84;*/
/*    position: sticky;*/
/*    top: 0;*/
/*}*/

/*.danger-btn{*/
/*    background: red!important;*/
/*}*/
/*.deleteBtn{*/
/*    cursor: pointer;*/
/*}*/
.mainHome {
    height: 100vh;
    background-color: #6F7E84;
}

.logoutBtn {
    flex-direction: row;
    /*background-color: red;*/
    width: 230px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logoutBtnView {
    border-radius: 20px;
    width: 120px;
    height: 40px;
    border-color: transparent;
    background-color: #232323;
    color: #FF9600;
}

.header-Container {
    flex-direction: row;
    /*background-color: #1c7430;*/
    /*background-color: orange;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeLogo {
    padding-right: 10vh;
}

.userProfileLogo {
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.FooterHome {
    right: 0;
    left: 0;
    bottom: 0;
    margin-bottom: 45px;
}

.FooterBtnHome {
    background-color: #232323;
    width: 130px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #FFA300;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    border-color: transparent;
    margin-left: 1vw;
}

.FooterBtnHome1 {
    background-color: #232323;
    width: 150px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #FFA300;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    margin-left: 1vw;
}

.LogoView {
    width: 12vw;
}

.HeaderlogoHome {
    width: 180px;
    height: 140px;
}

.headerTextHome {
    font-family: 'Rubik', sans-serif;
    font-size: 35px;
    text-align: left;
    justify-content: center;

}

.tblHeadTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
}

.tblValTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    vertical-align: middle !important;
    min-width: 100px;
}

.tblTitle {
    font-size: 15px;
    font-weight: bold;
}

.tblTitle1 {
    font-size: 15px;
    font-weight: bold;
    padding-left: 5vw;
}

.tblTitle2 {
    font-size: 15px;
    font-weight: bold;
    padding-right: 14vw;
}

.rowTitle {
    font-size: 15px;
    font-weight: bold;
    padding-left: 2vw;
}

.rowInvoiceTitle {
    font-size: 15px;
}

.subView {
    color: black;
    display: flex;
    position: relative;
    flex-direction: row;
}

.subView1 {
    height: 8vh;
    width: 72.5vw;
    color: black;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#searchTxtInput {
    color: black;
    height: 35px;
    border-radius: 20px;
    outline: none;
    margin-right: 10px;
    text-align: left;
}

#searchTxtInput::placeholder {
    color: lightgray;
}

.subDiv {
    overflow-y: auto;
    white-space: nowrap;
    height: 500px;
}

.searchBtnHome {
    background-color: #FCCF7F;
    height: 35px;
    width: 90px;
    border-radius: 22px;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.copy {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.subView2 {
    height: 6vh;
    width: 60vw;
    color: black;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tblRowView {
    height: 6vh;
    width: 1460px;
    color: black;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 4vw;
    margin-bottom: 1vh;
}

.tblRowSubView {
    height: 6vh;
    width: 1300px;
    color: black;
    background-color: #FFA300;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    border-radius: 28px;
    padding-left: 5px;
}

.tblRowSubView1 {
    height: 5.3vh;
    width: 500px;
    color: black;
    background-color: #FFA300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 28px;
}

.tblRowInvoiceView {
    height: 6vh;
    width: 300px;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2vw;
}

.tblRowLinkView {
    height: 6vh;
    width: 300px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
}

.tblRowbtnView {
    flex-direction: row;
    width: 200px;
    color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    vertical-align: middle !important;
}

.tblRowbtnView1 {
    height: 6vh;
    width: 100px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
}

.rowBtnHome {
    background-color: #232323;
    height: 25px;
    width: 80px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-color: white;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 9px;
}

.rowBtnHome1 {
    height: 25px;
    width: 100px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: bold;
    vertical-align: middle !important;
}

.tdStyle {
    background-color: #FCCF7F;
    width: 250px;
    min-width: 100px;
}


#rowStyle {
    background-color: #FCCF7F;
    height: 50px;
}

.tablefirsttab {
    background: #FFA300;
    border-radius: 26px;
    height: 50px;
    padding-left: 10px;
    align-items: center;
    display: flex;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    text-align: left;
    left: 10px !important;
}

table {
    border-collapse: separate;
    border-spacing: 0 10px;
}

td:first-child {
    border-left-style: solid;
    border-top-left-radius: 28px !important;
    border-bottom-left-radius: 28px !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
}

td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 28px;
    border-top-right-radius: 28px;
}

.tableStyle {
    overflow-y: auto;
}

.tableView {
    height: 500px;
    z-index: 10;
}

.okBtn {
    width: 25px;
    height: 25px;
}

.okBtnDownlode {
    width: 20px;
    height: 20px;
    right: 0;
    display: flex;
    margin-top: 5px;
    cursor: pointer;
}

@media screen and (max-width: 490px) {
    .subView1 {
        justify-content: center;
    }

    .headerTextHome {
        font-size: 30px;
        padding-left: 0;
        text-align: center;
    }
}

.btn:hover {
    color: #ffffff !important;
    text-decoration: none;
}


@media (min-width: 0px) and (max-width: 1000px) {
    .mainHome {
        height: 100vh;
        padding-bottom: 20px;
    }
}

@media (max-width: 700px) {
    .subView {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    #searchTxtInput {
        width: 100%;
    }
}

.search_div {
    width: -webkit-fill-available;
    gap: 6px;
}

th {
    background: #6F7E84;
    position: sticky;
    top: 0;
}

.danger-btn{
    background: red!important;
}
.deleteBtn{
    cursor: pointer;
}
