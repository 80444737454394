.App2 {
    /*font-family: sans-serif;*/
    background-color: lightgrey;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    display: flex;
}

.sub{
    width: 21cm;
    min-height: 25.7cm;
    padding: 3px;
    background-color: white;
    /*text-align: center;*/
}

.LogoViewT{
    top: 25px;
    left: 45px;
    width: 250px;
    height: 200px;
}

.titleStyle{
    font-size: 25px;
}

.titleStyle1{
    font-size: 15px;
}

.subViewT{
    text-align: justify;
}

.headerTitle{
    font-size: 14px;
}
