/*.container{*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/
.AppTest {
    /*background-color: #6F7E84;*/
    background-color: #FFA300;
    /*height: 100vh;*/
    /*width: 100vw;*/
    /*display: flex;*/
    /*align-items: flex-start;*/
    /*justify-content: flex-end;*/
    /*text-align: center;*/
    /*!*background-color: #6F7E84;*!*/
    /*min-height: 100vh;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*display: flex;*/
    /*justify-content: center;*/
}

.App-body-txt {
    max-width: 600px;
    width: 100%;
    padding: 1rem 1.5rem;
    /*color: white;*/
    /*background-color: #61dafb;*/
}

.mainContainer {
    display: flex;
    width: 100%;
    /*height: 100vh;*/
}

.hdTxtView {
    /*width: 200px;*/
    /*height: 50px;*/
    /*margin-top: -15px;*/
    /*margin-left: 15%;*/
}

.hdTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    /*background-color: white;*/
}

.lbTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
}

.lbTxt2 {
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: bold;
}
.help{
    cursor: pointer;
}

.lbTxt2Sub {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: bold;
}
p{
    font-style: italic!important;
    font-weight: lighter!important;
}

.TitleSubView {
    margin-top: 5px;
    /*padding: 1rem 1.5rem;*/
    height: auto;
    /*background-color: red;*/
    padding-top: 5px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
}

.txtAreas {
    border-radius: 15px;
    max-height: 80px;
    min-height: 50px;
    background-color: white;
}

.hideBar {
    overflow: hidden;
}

.txt {
    color: black;
    width: 1150px;
    height: 4vh;
    border-radius: 20px;
    margin-bottom: 3vh;
    outline: none;
}

.txt::placeholder {
    color: gray;
}

.title {
    text-align: left;
    padding-left: 20px;
    font-size: 18px;
    font-weight: unset;
}


.headerText {
    font-size: 18px;
    font-weight: unset;
    /*text-align: left;*/
}

.headSub {
    background-color: red;
}

.footerClient {
    height: 15vh;
    width: 75vw;
    //background-color: red;
    display: flex;
    /*right: 0;*/
    /*bottom: 0;*/
    align-items: center;
    justify-content: flex-end;
    //position: relative;

    /*height: 50px;*/
    /*!*background-color: red;*!*/
    /*display: flex;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*!*width: 200px;*!*/
    /*!*align-items: center;*!*/
    /*!*margin-top: 400px;*!*/
    /*justify-content: flex-end;*/
    /*position: fixed;*/
    /*margin-bottom: 20px;*/
    /*padding-right: 60px;*/
}
.clientHead{
    /*height: 20%;*/
    /*width: 100vw;*/
    /*flex-direction: column;*/
    /*display: flex;*/
    /*right: 0;*/
    /*top: 0;*/
    /*align-items: flex-start;*/
    /*position: absolute;*/
}
.clientHead2{
    display: inline-block;
    text-align: center;
}

.ClientFooterBtn {
    background-color: black;
    width: 160px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    //position: relative;
    color: #FFA300;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: bold;
    border: hidden;
    //margin-left: 50%;
}

.App-header {
    height: 25vh;
    width: 100vw;
    /*flex-direction: row;*/
    padding-left: 20px;
    background-color: black;
    display: flex;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
}

.HeaderlogoView {
    /*margin-left: 15%;*/
}

/*.titleView {*/
/*    !*position: absolute;*!*/
/*    top: 0;*/
/*    left: 0;*/
/*    flex-direction: column;*/
/*    padding-top: 8vh;*/
/*    !*background-color: white;*!*/
/*    width: 12vw;*/
/*}*/

.scrollBody {
    overflow: -moz-scrollbars-vertical;
    height: auto;
    padding-top: 50px;
}

/*.authorDiv {*/
/*    !*background-color: red;*!*/
/*    width: 800px;*/
/*    height: auto;*/
/*}*/

.afflicationDiv {
    /*background-color: red;*/
    /*width: 800px;*/
    /*height: auto;*/
    /*margin-top: 5%;*/
}

.editorDiv{
    /*width: 800px;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    /*display: flex;*/
}

.genarateDiv {
    /*background-color: green;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    /*width: 800px;*/
    /*height: auto;*/
    margin-bottom: 70px;
}

.pluseIcon{
    width: 40px;
    height: 40px;
    cursor: pointer;
    /*align-items: center;*/
    /*justify-content: center;*/
    /*background-color: #1c7430;*/
}

.imageTxtView{
    width: 200px;
    height: 40px;
    //background-color: red;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-top: 5px;
}

.imageTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: bold;
    //margin-top: 20%;
}
.imageTxt2{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.boldFont{
    /*font-family: 'Rubik', sans-serif;*/
    /*font-size: 14px;*/
    font-weight: bold;
}

.ClientHead {
    width: 20%;
    height: 350px;
    //background-color: red;
    //position: absolute;
    margin-left: 100%;
    flex-direction: column;
    /*margin-top: -5%;*/
    align-items: center;
    justify-content: center;
    margin-top: 1%;
}

.sampleLogo {
    width: 200px;
    height: 300px;
    cursor: pointer;
    //margin-top: 5%;
    /*width: 345px;*/
    /*//height: 485px;*/
}

.PluseIconDiv{
    /*height: 150px;*/
    /*width: 150px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: flex-start;*/
    /*background-color: white;*/
}


@media screen and (max-width: 470px) {
    .Footer{
        justify-content: center;
        padding-right: 0;
    }
}

@media screen and (max-width: 1700px) {
    .ClientHead {
        width: 100px;
        height: 300px;
        //background-color: green;
        //position: absolute;
        //margin-left: 60%;
        //margin-top: -30%;
        //display: none;
        align-items: center;
        justify-content: center;
    }
    .sampleLogo {
        width: 100px;
        height: 200px;
        margin-right: 45%;
    }

    .imageTxtView{
        width: 120px;
        height: 60px;
        //background-color: red;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-left: -10%;
        margin-top: 5px;
    }

    .imageTxt{
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-weight: bold;
    }
    .imageTxt2{
        font-family: 'Rubik', sans-serif;
        font-size: 10px;
        font-weight: bold;
    }
}

.react-viewer-close {
    background: red!important;
}

.tox .tox-editor-header {
    z-index: 0!important;
}

.viewImg{
    width: 514px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid;
    padding: 8px;
}

@media all and (max-width: 603px) and (min-width: 0) {
    .viewImg{
        width: -webkit-fill-available!important;
    }

}

.close-button {
    float: right;
    top: -24px;
    right: -24px;
}

.closeBtn{
    font-size: 30px;
}
