#main {
    background-color: #FFA300;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.landingLogo{
    width: 300px;
    height: 230px;
    align-items: center;
    justify-content: center;
}

.landingHead {
    width: 90vw;
    height: 350px;
    /*background-color: black;*/
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: -5%;
}

.landingHeadTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.landingHeadTxtSub {
    font-family: 'Rubik', sans-serif;
    font-size: 25px;
    font-weight: bold;
    margin-top: 2%;
    text-align: center;
}

.animated {
    -webkit-animation-duration: .10s;
    animation-duration: .10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-45px);
    }
}
@-webkit-keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-45px);
    }
}
.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
#animated-example {
    width: 300px;
    height: 230px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: relative;
    border-radius: 50%;
}
hr {
    position: relative;
    top: 92px;
    left: -300px;
    width: 200px;
}
