#mainPdf {
    background-color: #FFA300;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.downloadBtn {
    height: 5vh;
    width: 200px;
    margin-bottom: 20px;
    background-color: black;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    border-color: transparent;
}
.downloadBtTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    /*font-weight: bold;*/
}
.CloudImg{
    width: 120px;
    height: 120px;
}

.btnBDownload {
    height: 5vh;
    width: 200px;
    margin-bottom: 20px;
    background-color: black;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    /*font-weight: bold;*/
    /*font-size: 15px;*/
}

.animated {
    -webkit-animation-duration: .10s;
    animation-duration: .10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-45px);
    }
}
@-webkit-keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-45px);
    }
}
.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
#animated-example {
    width: 300px;
    height: 230px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: relative;
    border-radius: 50%;
}
