.App1 {
    text-align: center;
    background-color: #6F7E84;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
}

.sBtnTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header1 {
    height: 15vh;
    width: 100vw;
    flex-direction: row;
    /*background-color: green;*/
    display: flex;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: space-between;
    position: absolute;
}


.App-body {
    align-items: center;
    justify-content: center;
    display: flex;
    /*height: 40vh;*/
    width: 100vw;
    margin-top: 10px;

    flex-direction: column;
    /*background-color: #61dafb;*/
}

.App-link {
    color: #61dafb;
}

.Text {
    color: #000;
}

.btnA {
    height: 40px;
    width: 425px;
    margin-bottom: 40px;
    background-color: #FF9600;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: black;
    font-size: 15px;
}



.FooterBtn {
    background-color: black;
    height: 5vh;
    width: 160px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    color: #FF9600;
    font-size: 15px;
    font-weight: bold;
}

.backBtn {
    padding-left: 80px;
    font-size: 16px;
    top: 25px;
    cursor: pointer;
    position: absolute;
}

.SettingHeadTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    font-weight: bold;
    /*color: black;*/
}

.bTxt {
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.editBtnTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.logo {
    /*padding-right: 90px;*/
    /*font-size: 20px;*/
    padding-left: 20px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 470px) {
    .Footer{
        justify-content: center;
        padding-right: 0;
    }
}

@media screen and (max-width: 440px) {
    .Footer{
        justify-content: center;
        padding-right: 0;
    }

    .btnA{
        width: 250px;
    }

    .logo{
        padding-right: 40px;
        width: 100px;
        height: 50px;
    }

    .backBtn{
        padding-left: 40px;
    }
}
