.mainDiv {
    height: 100vh;
    background-color: #6F7E84;
    padding: 30px 0 20px 0;
}

#backPara {
    top: 25px;
    left: 25px;
    cursor: pointer;
}

#ClosePara {
    top: 25px;
    right: 25px;
    cursor: pointer;
}

.closeImg{
    width: 25px;
}

.txtInputVal{
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
}

.backBtnTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
}

.labelTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
}


.headTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 40px;
}

.line{
    color: black;
    border-width: 1px;
}

.proImgStyles{
    height: 110px;
    width: 110px;
    border: 1px solid white;
    cursor: pointer;
}

.btnTxt{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.editLabel{
    font-family: 'Rubik', sans-serif;
    font-size: 10px;
}

#profileImg {
    width: 80px;
    cursor: pointer;
}

#profileName {
    font-size: 40px;
}

#firstCol {
    border-right: 1px solid #707070;
}

.inputs {
    border-radius: 20px;
    width: 90%;
    height: 32px;
    margin-bottom: 5px;
}

#updateBtn {
    background-color: black;
    color: orange;
    border-radius: 20px;
    width: 125px;
}

#createAdminBtn {
    background-color: orange;
    color: black;
    border-radius: 20px;
    width: 125px;
}

.setWidth {
    width: 70%;
}

.editTxt {
    font-size: 13px;
    cursor: pointer;
}

.FooterBtnSignUp{
    background-color: black;
    height: 5vh;
    width: 160px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    color: orange;
    font-size: 15px;
    font-weight: bold;
}

.subImageCrop{
    opacity: 1;
}

/*media queries*/
@media screen and (max-width: 1199px) {
    .setRight {
        text-align: right !important;
    }

    .mainDiv {
        /*height: unset;*/
        padding: 20px 0;
    }

    #firstCol {
        border: none;
    }
}

@media screen and (max-width: 670px) {
    .setWidth {
        width: 100%;
    }

    .inputs {
        width: 92%;
    }

    .setBlock {
        display: block !important;
    }

    /*#backPara {*/
    /*    top: 5px;*/
    /*    right: 20px;*/
    /*    left: unset;*/
    /*}*/

    #setBlock {
        display: block !important;
        text-align: center;
        padding-left: 0 !important;
    }
}

@media screen and (max-width: 470px) {
    .inputs {
        width: 90%;
    }
}

@media screen and (max-width: 410px) {
    #profileName {
        font-size: 30px;
    }
}

@media screen and (max-width: 390px) {
    .inputs {
        width: 87%;
    }
}

/*@media (min-width: 1190px) {*/
/*    .mainDiv{*/
/*        padding-top: 150px;*/
/*    }*/
/*}*/
