.Apps {
    background-color: #FFA300;
    height: 100%;
}

.App-body1 {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
}

.btTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.titleTxtView {
    width: 40%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleTxtView2 {
    height: auto;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.titleFont {
    font-family: 'Rubik', sans-serif;
    font-size: 25px;
    margin-top: 20px;
    font-weight: bold;
}

.footerFont {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.UserLogoFont {
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    width: inherit;
}

.bottomDiv {
    width: 153px;
}

.Footer {
    height: 10vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FooterBt {
    background-color: white;
    height: 5vh;
    width: 160px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    color: black;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: bolder;
    border-color: transparent;
}

.Headerlogo {
    width: 200px;
    border-radius: 100%;
}

.Userlogo {
    width: 80px;
    height: 80px;
    margin-right: 3%;
    border-radius: 100px;
}

.Userlogo1 {
    width: auto;
    height: auto;
    padding-top: 5px;
    margin-left: 2.8%;
}

.Userlogo2 {
    width: auto;
    height: auto;
    padding-top: 5px;
    margin-left: 2%;
}

.Otherlogo {
    width: 200px;
    margin: 10px;
    /*margin-top: 20px;*/
    height: 120px;
}

.titleView2 {
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.titleView2Bottom {
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.userLogoTxtView {
    width: 12vw;
    height: 100px;
    margin-left: 88%;
    position: absolute;
    margin-top: 8%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dateSelect {
    width: 200px !important;
    display: inline-block!important;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    background-color: #6f7e84;
    background-clip: padding-box;
    border: 1px solid #6f7e84;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.downloadDateDiv{
    display: inline-grid;
}
.btnB {
    height: 50px;
    width: 200px;
    background-color: #6F7E84;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    left: 0;
    right: 0;
    margin: auto;
}

.btn1 {
    height: 5vh;
    width: 200px;
    margin-bottom: 20px;
    background-color: black;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
}

.indicator {
    height: 25vh;
    width: 10vw;
    margin-bottom: 40px;
}

.exitBtn {
    border-color: transparent;
     height: 5vh;
    width: 160px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;

}

.subTxtView {
    width: 25%;
    height: 30px;
    margin-top: -10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.subViewFont {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    text-align: center;
}

.subViewFontTwo {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
}

@media screen and (max-width: 470px) {
    .Footer {
        justify-content: center;
        padding-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .titleView2Bottom {
        display: block;
        height: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin: 10px;
    }

    .titleTxtView2 {
        height: auto;
        display: block;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
    }
}

@media screen and (max-width: 984px) {

    .titleTxtView2 {
        height: auto;
        display: block;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
    }
}

@media screen and (max-width: 440px) {
    .titleView {
        padding-left: 0;
        justify-content: center;
    }

    .titleFont {
        font-size: 12px;
        font-weight: bold;
    }

    .App-body1 {
        margin-top: 50%;
    }

    .Headerlogo {
        width: 90px;
        height: 80px;
    }

    .Userlogo {
        width: 50px;
        height: 50px;
    }

    .subTxtView {
        width: 70%;
        height: 40px;
    }

    .subViewFont {
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .userLogoTxtView {
        width: 18vw;
        height: 100px;
         margin-left: 82%;
        position: absolute;
        margin-top: 15%;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}

@media screen and (max-width: 1190px) {
    .subTxtView {
        width: 70%;
        height: 60px;
    }

    .subViewFont {
        font-size: 15px;
    }

    .App-body1 {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 40vh;
        width: 100vw;
        flex-direction: column;
        margin-top: 10%;
    }

    .userLogoTxtView {
        width: 20vw;
        height: 100px;
         margin-left: 80%;
        position: absolute;
        margin-top: 18%;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}

@media (max-width: 766px) and (min-width: 320px) {
    .downloadDiv {
        width: auto;
        height: 300px;
        position: relative;
        right: 0;
        left: 0;
        bottom: 0;
        top: 54px !important;
        margin: auto;
    }
}


.downloadDiv {
    width: auto;
    height: 300px;
    position: relative;
    right: 0;
    left: 0;
    bottom: 0;
    top: 130px;
    margin: auto;
}

.buttonDiv {
    text-align: center;
    margin: 10px;
    gap: 22px;
    padding: 10px;
}

.logoclass {
}
