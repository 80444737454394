#mainDivHome {
    background-color: #A8B1B5;
    height: 100vh;
}

#subDiv {
    /*background-color: orange;*/
    height: 40vh;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headTitalView {
    /*height: 300px;*/
    /*background-color: green;*/
    display: flex;
    align-items: flex-start;
    justify-content: center;
 }

.headTitalView2 {
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.headTital {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.headTital2 {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.emailTxt {
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
}

.inputsLogin {
    border-radius: 20px;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
}

.forget-button {
    cursor: pointer;
}

#absoluteDiv {
    width: 450px;
    bottom: -6px;
}

#loginButton {
    background-color: black;
    color: orange;
    width: 120px;
    border-radius: 30px;
    box-shadow: 0 3px 0 0 orange;
}

#file-input {
    display: none;
}

@media screen and (max-width: 470px) {
    #absoluteDiv {
        width: 100%;
    }

}

@media screen and (max-width: 1190px) {
    .headTitalView2 {
        height: 300px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        /*margin-left: 5%;*/
        /*position: relative;*/
        width: 100%;
        /*margin-top: -300px;*/
    }

    /*.headTitalView {*/
    /*    height: 200px;*/
    /*    !*background-color: green;*!*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    margin-top: 20%;*/
    /*}*/

    .headTital2 {
        font-family: 'Rubik', sans-serif;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
}

@media screen and (max-width: 1024px) {
    /*.headTitalView2 {*/
    /*    height: 300px;*/
    /*    background-color: orange;*/
    /*    display: flex;*/
    /*    align-items: flex-end;*/
    /*    justify-content: center;*/
    /*    !*margin-left: 5%;*!*/
    /*    !*position: relative;*!*/
    /*    width: 100%;*/
    /*    !*margin-top: -300px;*!*/
    /*}*/
    /*.headTitalView {*/
    /*    height: 500px;*/
    /*    !*background-color: green;*!*/
    /*    display: flex;*/
    /*    align-items: flex-start;*/
    /*    justify-content: center;*/
    /*    !*margin-top: 20%;*!*/
    /*}*/

    /*.headTital2 {*/
    /*    font-family: 'Rubik', sans-serif;*/
    /*    font-size: 40px;*/
    /*    font-weight: bold;*/
    /*    margin-left: 5%;*/
    /*    text-align: center;*/
    /*}*/
}

@media screen and (max-width: 1189px) {
    .headTitalView2 {
        height: 120px;
        /*background-color: red;*/
        display: flex;
        align-items: center;
        justify-content: center;
        /*margin-left: 5%;*/
        /*position: relative;*/
        width: 100%;
        /*margin-top: -300px;*/
    }

    /*.headTitalView {*/
    /*    height: 500px;*/
    /*    !*background-color: green;*!*/
    /*    display: flex;*/
    /*    align-items: flex-start;*/
    /*    justify-content: center;*/
    /*    !*margin-top: 20%;*!*/
    /*}*/

    .headTital2 {
        font-family: 'Rubik', sans-serif;
        font-size: 20px;
        font-weight: bold;
        margin-right: 5%;
        text-align: center;
    }
}


.modalStyle {
    width: 500px;
}

.setWidthLogin {
    width: 90%;
}

.closeImgLogin {
    width: 25px;
}


#CloseModal {
    cursor: pointer;
}

/*.inputsLogin {*/
/*    border-radius: 20px;*/
/*    width: 90%;*/
/*    height: 32px;*/
/*    margin-bottom: 5px;*/
/*}*/
/*========================================================*/

.mainLogin{
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
}

.loginDetailsView{
    position: relative;
    background: white;
    height: 342px;
    width: 300px;

}

.loginPage{
    background: orange;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}
