.App {
    text-align: center;
    background-color: #FFA300;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
}

.A4Body {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    height: auto;
    /*height: 842px;*/
    /*padding: 2%;*/
    width: 595px;
    flex-direction: column;
    /*margin-top: 10%;*/
    background-color: white;
}

.A4BodyHead{
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    height: 130px;
    /*padding: 2%;*/
    width: 530px;
    /*margin-top: 10%;*/
    /*background-color: red;*/
    margin-top: 3%;
}

.A4HeadTxt{
    height: 110px;
    display: flex;
    background-color: #E6E6FA;
    /*padding: 2%;*/
    width: 450px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.A4HeadLogo{
    height: 120px;
    display: flex;
    /*background-color: #61dafb;*/
    align-items: center;
    justify-content: center;
    /*padding: 2%;*/
    /*width: 130px;*/
    position: absolute;
}

.A4HeadLogoImg{
    width: 180px;
    height: auto;
    margin-left: 360px;
}

.A4HBodyLogoImg{
    width: 140px;
    height: auto;
    /*margin-left: 340px;*/
}

.A4HBodyLogoImg5{
    width: 160px;
    height: 100px;
    /*margin-left: 340px;*/
}

.A4HBodyLogoImg3{
    width: 120px;
    height: 70px;
    /*margin-left: 340px;*/
}

.A4HBodyLogoImg4{
    width: 150px;
    height: 80px;
    /*margin-left: 340px;*/
}

.A4HBodyLogoImg2{
    width: 200px;
    height: 100px;
    /*background-color: #61dafb;*/
    /*margin-left: 340px;*/
}
.A4HBodyLogoImg3View{
    /*background-color: red;*/
    height: 150px;
    width: 150px;
    flex-direction: column;
    display: flex;
    align-items: center;

}

.A4HeadTxtFont{
    font-size: 12px;
    font-family: 'Rubik', sans-serif;
    margin-left: 5px;
}

.A4HeadTxtFont1{
    font-size: 13px;
    font-family: 'Rubik', sans-serif;
    color: green;
    margin-left: 5px;
}

.A4BodyTitleView{
    display: flex;
    /*background-color: red;*/
    width: 530px;
    height: 50px;
    align-items: flex-end;
    justify-content: center;
}

.A4BodyTitle{
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
}

.A4BodyView{
    display: flex;
    /*background-color: green;*/
    width: 530px;
    height: 80px;
    align-items: center;
    justify-content: center;
    /*margin-top: 2%;*/
}

.A4BodyViewTxt{
    font-size: 12px;
    font-family: 'Rubik', sans-serif;
    text-align: justify;
}

.A4BodyViewSub{
    display: flex;
    /*background-color: red;*/
    width: 530px;
    height: auto;
    /*height: 80px;*/
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /*margin-top: 2%;*/
}

.A4BodyViewSub5{
    display: flex;
    /*background-color: green;*/
    width: 530px;
    height: auto;
    /*height: 80px;*/
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /*margin-top: 2%;*/
}

.A4BodyViewSubTitle{
    display: flex;
    /*background-color: yellow;*/
    width: 530px;
    height: 50px;
    /*height: 80px;*/
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /*margin-top: 2%;*/
}

.A4BodyViewSubOne{
    display: flex;
    /*background-color: red;*/
    width: 530px;
    height: 20px;
    align-items: center;
    justify-content: flex-start;
    /*flex-direction: row;*/
    /*margin-top: 2%;*/
}

.A4BodyViewSubTwo{
    display: flex;
    /*background-color: yellow;*/
    width: 530px;
    height: 120px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    /*margin-top: %;*/
}

.A4FooterView{
    display: flex;
    /*background-color: yellow;*/
    width: 530px;
    height: 120px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 5%;
}

.A4FooterViewOne{
    display: flex;
    /*background-color: #FF9600;*/
    width: 350px;
    height: 120px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /*margin-top: 2%;*/
}

.A4FooterViewTwo{
    display: flex;
    /*background-color: #707070;*/
    width: 180px;
    height: 120px;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    /*margin-top: 2%;*/
}

.A4Margin{
    margin-top: 10px;
}

.A4MarginTop{
    margin-top: 2%;
}

.A4Title{
    font-size: 13px;
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
}

.A4TitleSub{
    font-size: 12px;
    font-family: 'Rubik', sans-serif;
    text-align: justify;
    /*background-color: #007bff;*/
}

.A4FooterTxt{
    font-size: 11px;
    font-family: 'Rubik', sans-serif;
    text-align: justify;
    line-height: 20px;
    margin-left: 8px;
    color: grey;
}

.A4FooterTxtSub{
    font-size: 11px;
    font-family: 'Rubik', sans-serif;
    text-align: justify;
    line-height: 20px;
    font-weight: bold;
}

.A4footerLogo{
    width: 530px;
    height: 13px;
}

.A4FooterViewThree{
    width: 380px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*background-color: red;*/
}

